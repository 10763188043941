import React from "react"
import HeaderSub from "../../components/header_sub"
import LayoutOuter from "../../components/layout_outer"
import LayoutInner from "../../components/layout_inner"
import Paragraph from "../../components/paragraph"

export default function PrivacyWebsite() {
  return (
    <LayoutOuter>
      <LayoutInner>
        <div className="pb-16">
          <div className="py-16">
            <HeaderSub title="Privacy" subtitle="Tempory Website" />
          </div>

          <Paragraph
            title="Types of data collected"
            text="While using this website, you may be asked to provide certain personally identifiable information that can be used to contact or identify you ('Personal Data'). Personally identifiable information may include information about how the website is accessed and used ('Usage Data'). This includes your Internet Protocol address, browser type, browser version, the pages you visit, the time and date of your visit and diagnostic data."
          />

          <Paragraph
            title="Use of data"
            text="The collected data is used to provide and maintain the website, to allow you to participate in interactive features of this website when you choose to do so, to provide analysis, to monitor the usage of the website and to detect and address technical issues."
          />

          <Paragraph
            title="Security of data"
            text="The security of your data is important, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure and I cannot guarantee its absolute security."
          />

          <Paragraph
            title="Links to other sites"
            text="This website contains links to other sites that are not operated by me. If you click on a third party link, you will be directed to that third party's site. I strongly advise you to review the Privacy Policy of every site you visit. I have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services."
          />

          <Paragraph
            title="Changes to this Privacy Policy"
            text="This Privacy Policy may get changed from time to time. You will not be notified about any changes. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page."
          />

          <Paragraph
            title="Contact"
            text="If you have any questions about this Privacy Policy, please send an email to: tempory.app@gmail.com"
          />
        </div>
      </LayoutInner>
    </LayoutOuter>
  )
}
